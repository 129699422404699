import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Seo } from '../../components/common/Seo';
import { ToastFunc, ToastQueue } from '../../components/common/Toast';
import ErrorForm from '../../components/forms/ErrorForm';
import { connexionSchema, connexionType } from '../../schema/LoginSchema';
import AuthService from '../../services/auth.service';
import UsersService from '../../services/users.service';
import LoginForm from './forms/LoginForm';
import TFAForm from './forms/TFAForm';
import UpdateSkemeetPasswordForm from './forms/UpdateSkemeetPasswordForm';

const LoginComponent = () => {
  const usersService = new UsersService();
  const authService = new AuthService();
  const searchQueryParams = useSearchParams()[0];
  const [pageStep, setPageStep] = useState<string>('connexion');
  const [redirectUrlSaved, setRedirectUrlSaved] = useState<boolean>(false);
  const [skemeetUserInfos, setSkemeetUserInfos] = useState<{
    temporaryAccessToken: string;
    firstname: string;
    lastname: string;
    cellPhone: string;
  }>({
    temporaryAccessToken: '',
    firstname: '',
    lastname: '',
    cellPhone: '',
  });
  const navigate = useNavigate();
  const toastQueueRef = useRef<ToastFunc>();

  type AxiosErrorDatas = {
    error: string;
    message: string;
    statusCode: number;
  };

  const connexionMethods = useForm<connexionType>({
    resolver: yupResolver(connexionSchema),
    mode: 'onSubmit',
    defaultValues: {
      nickNameOrEmail: '',
      password: '',
    },
  });

  const redirect = async () => {
    const redirectUrl = localStorage.getItem('redirectUrl');

    if (redirectUrl) {
      window.location.href = redirectUrl.includes('?')
        ? `${redirectUrl}&accessToken=${localStorage.getItem('accessToken')}&refreshToken=${localStorage.getItem('refreshToken')}`
        : `${redirectUrl}?accessToken=${localStorage.getItem('accessToken')}&refreshToken=${localStorage.getItem('refreshToken')}`;
    } else {
      navigate(`/dashboard/apps`);
    }
  };

  const setTokenAndRedirect = async (accessToken: string, refreshToken: string) => {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);

    redirect();
  };

  const checkToken = async () => {
    try {
      await usersService.getUserData();
    } catch (error) {
      const errData = (error as AxiosError).response?.data as AxiosErrorDatas;

      if (errData.statusCode === 401) {
        await refreshToken();
      }
    }
  };

  const refreshToken = async () => {
    try {
      const { accessToken, refreshToken } = await authService.regenerateToken();

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);

      window.location.reload();
    } catch (error) {
      const errData = (error as AxiosError).response?.data as AxiosErrorDatas;

      if (errData.statusCode === 401) {
        navigate(`/logout`);
      }
      if (errData.statusCode === 400) {
        toastQueueRef.current?.addToast({
          timer: 10000,
          description: errData.message,
          type: 'ERROR',
        });
      }
    }
  };

  useEffect(() => {
    (async () => {
      const localStorageRedirectUrl = searchQueryParams.get('localStorageRedirectUrl');
      const redirectUrl = localStorageRedirectUrl ? localStorage.getItem('redirectUrl') : searchQueryParams.get('redirectUrl');
      if (!localStorageRedirectUrl) {
        localStorage.setItem('redirectUrl', redirectUrl ? decodeURIComponent(redirectUrl) : '');
      }
      setRedirectUrlSaved(true);

      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        await checkToken();

        // Garde fou pour éviter les boucles infinis entre les apps et la page de connexion
        const lastRedirectWithTenSecond = new Date(localStorage.getItem('lastRedirect') || '0');
        lastRedirectWithTenSecond.setSeconds(lastRedirectWithTenSecond.getSeconds() + 10);

        if (lastRedirectWithTenSecond > new Date()) {
          navigate(`/logout`);
        } else {
          localStorage.setItem('lastRedirect', new Date().toISOString());

          redirect();
        }
      }
    })();
  }, []);

  return (
    <>
      <ToastQueue ref={toastQueueRef} />
      {redirectUrlSaved && (
        <div className='z-[10] w-full rounded-lg bg-white p-10'>
          {
            {
              connexion: (
                <LoginForm
                  setPageStep={setPageStep}
                  setSkemeetUserInfos={setSkemeetUserInfos}
                  setTokenAndRedirect={setTokenAndRedirect}
                  connexionMethods={connexionMethods}
                  toastQueueRef={toastQueueRef}
                />
              ),
              connexionStepTwo: (
                <TFAForm
                  setPageStep={setPageStep}
                  setTokenAndRedirect={setTokenAndRedirect}
                  connexionMethods={connexionMethods}
                  toastQueueRef={toastQueueRef}
                />
              ),
              updateSkemeetPassword: (
                <UpdateSkemeetPasswordForm
                  setTokenAndRedirect={setTokenAndRedirect}
                  skemeetUserInfos={skemeetUserInfos}
                  connexionMethods={connexionMethods}
                  toastQueueRef={toastQueueRef}
                />
              ),
              error: <ErrorForm onClick={() => setPageStep('connexion')} />,
            }[pageStep]
          }
        </div>
      )}
      <Seo title={'Connexion'} />
    </>
  );
};

export default LoginComponent;
