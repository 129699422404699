import { AxiosError } from 'axios';
import AuthService from './auth.service';

export const refreshToken = async () => {
  const authService = new AuthService();

  type AxiosErrorDatas = {
    error: string;
    message: string;
    statusCode: number;
  };

  try {
    const { accessToken, refreshToken } = await authService.regenerateToken();

    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);

    window.location.reload();
  } catch (error) {
    const errData = (error as AxiosError).response?.data as AxiosErrorDatas;

    if (errData.statusCode === 401) {
      window.location.href = `${import.meta.env.VITE_APP_URL}/logout`;
    }
  }
};
