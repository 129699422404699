import { request } from './fetcher';

interface Login {
  nickNameOrEmail: string;
  password: string;
  fromSkemeet: boolean;
}
type LoginOTP = Login & {
  oneTimePassword: number;
};

export default class AuthService {
  async createUserFromSkemeetAndLogin({
    email,
    password,
    firstname,
    lastname,
    cellPhone,
  }: {
    email: string;
    password: string;
    firstname: string;
    lastname: string;
    cellPhone: string;
  }): Promise<{ accessToken: string; refreshToken: string }> {
    return await request({
      method: 'post',
      url: `/auth/create-user-from-skemeet-and-login`,
      data: {
        email,
        password,
        firstname,
        lastname,
        cellPhone,
      },
    });
  }

  async regenerateToken(): Promise<{ accessToken: string; refreshToken: string }> {
    return request({
      method: 'post',
      url: `/auth/regenerate-token`,
    });
  }

  async login(login: Login): Promise<{
    success: boolean;
    oneTimePasswordActivated: boolean;
    message: string;
    accessToken: string;
    refreshToken: string;
    changeSkemeetPassword?: boolean;
    firstname?: string;
    lastname?: string;
    cellPhone?: string;
    temporaryAccessToken?: string;
  }> {
    return request({
      method: 'post',
      url: `/auth/login`,
      data: {
        nickNameOrEmail: login.nickNameOrEmail,
        password: login.password,
        fromSkemeet: login.fromSkemeet,
      },
    });
  }

  async checkOneTimePassword(loginOTP: LoginOTP): Promise<{ accessToken: string; refreshToken: string }> {
    return await request({
      method: 'post',
      url: `/auth/check-one-time-password`,
      data: {
        nickNameOrEmail: loginOTP.nickNameOrEmail,
        password: loginOTP.password,
        oneTimePassword: loginOTP.oneTimePassword,
      },
    });
  }

  async getProSanteConnectLink(): Promise<{ authenticationUrl: string }> {
    return await request({
      method: 'get',
      url: `/auth/pro-sante-connect/connexion`,
    });
  }

  async proSanteConnectCheckUserInfo(code: string): Promise<{
    success: boolean;
    message: string;
    hasEmail: boolean;
    hasEmailConfirmed: boolean;
    appConnectEmail: string | null;
    accessToken: string;
    refreshToken: string;
    askMergeAccount: boolean;
    appToken: string;
    idToken: string;
  }> {
    return await request({
      method: 'post',
      url: `/auth/pro-sante-connect/checkUserInfo`,
      data: { code },
    });
  }

  async getFranceConnectLink(): Promise<{ authenticationUrl: string }> {
    return await request({
      method: 'get',
      url: `/auth/france-connect/connexion`,
    });
  }

  async getFranceConnectLogoutLink(idToken: string): Promise<{ logoutUrl: string }> {
    return await request({
      method: 'get',
      url: `/auth/france-connect/logout/${idToken}`,
    });
  }

  async franceConnectCheckUserInfo(code: string): Promise<{
    success: boolean;
    message: string;
    hasEmail: boolean;
    hasEmailConfirmed: boolean;
    appConnectEmail: string | null;
    accessToken: string;
    refreshToken: string;
    askMergeAccount: boolean;
    appToken: string;
    idToken: string;
  }> {
    return await request({
      method: 'post',
      url: `/auth/france-connect/checkUserInfo`,
      data: { code },
    });
  }

  async fcPscCreateAccount(appToken: string): Promise<{
    success: boolean;
    accessToken: string;
    refreshToken: string;
  }> {
    return await request({
      method: 'post',
      url: `/auth/FC-PSC/createAccount`,
      data: { appToken },
    });
  }

  async fcPscMergeAccount(appToken: string): Promise<{
    success: boolean;
  }> {
    return await request({
      method: 'post',
      url: `/auth/FC-PSC/mergeAccount`,
      data: { appToken },
    });
  }

  async resetNickname(email: string): Promise<void> {
    return await request({
      method: 'post',
      url: `/auth/reset-nickname`,
      data: { email, locationUrl: window.location.href },
    });
  }

  async resetNicknameConfirm(token: string, nickName: string): Promise<void> {
    return await request({
      method: 'post',
      url: `/auth/reset-nickname-confirm`,
      data: { token, nickName },
    });
  }

  async resetPassword(email: string): Promise<void> {
    return await request({
      method: 'post',
      url: `/auth/reset-password`,
      data: { email, locationUrl: window.location.href },
    });
  }

  async resetPasswordConfirm(token: string, password: string): Promise<void> {
    return await request({
      method: 'post',
      url: `/auth/reset-password-confirm`,
      data: { token, password },
    });
  }
}
