import { FC, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as Icons from '../icons';

type ClipboardCopyProps = {
  id?: string;
  className?: string;
  showText?: boolean;
  text: string;
};

const ClipboardCopy: FC<ClipboardCopyProps> = ({ id, className, showText, text }) => {
  const [copied, setCopied] = useState<boolean>(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <div id={id} className={`relative flex h-full items-center justify-start space-x-2 ${className ?? ''}`}>
      <CopyToClipboard text={text} onCopy={() => handleCopy()}>
        <button className={`group flex items-center ${copied && 'cursor-default'}`} onClick={e => e.preventDefault()}>
          {showText && <p className='sm:text-sm flex-1 text-left text-xs font-bold'>{text}</p>}
          <Icons.Copy className={`${!copied && 'opacity-50 group-hover:opacity-100'}`} />
          <span className={`!ml-[2px] text-sm text-green-dark underline-offset-2 ${!copied && 'group-hover:underline'}`}>
            {copied ? 'Copié !' : 'Copier'}
          </span>
        </button>
      </CopyToClipboard>
    </div>
  );
};

export default ClipboardCopy;
