import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AuthService from '../../services/auth.service';
import UsersService from '../../services/users.service';
import { Seo } from '../../components/common/Seo';
import * as Icon from '../../components/icons';
import { emailSchema, emailType } from '../../schema/CheckUserSchema';
import { BasicButton } from '../../components/common/Button';
import ConnexionFormHeader from '../../components/forms/FormHeader';
import { InputWord } from '../../components/common/Input';
import { ToastFunc, ToastQueue } from '../../components/common/Toast';

const CheckUserComponent = () => {
  const navigate = useNavigate();
  const searchQueryParams = useSearchParams()[0];
  const authService = new AuthService();
  const usersService = new UsersService();
  const toastQueueRef = useRef<ToastFunc>();

  const [checkUserInProgress, setCheckUserInProgress] = useState(true);
  const [franceConnect, setFranceConnect] = useState(false);
  const [pageStep, setPageStep] = useState('emailRequest');
  const [appToken, setAppToken] = useState('');
  const [appConnectEmail, setAppConnectEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  type AxiosErrorDatas = {
    error: string;
    message: string;
    statusCode: number;
  };

  const checkUserMethods = useForm<emailType>({
    resolver: yupResolver(emailSchema),
    mode: 'onSubmit',
    defaultValues: {
      email: '',
    },
  });

  useEffect(() => {
    checkUserInfo();
  }, []);

  const sendEmailValidation = async ({ email }: emailType) => {
    try {
      const code = searchQueryParams.get('code');

      if (code) {
        usersService.sendEmailValidation(email, appToken);

        setPageStep(`emailSendSuccess`);
      }
    } catch (error) {
      const errData = (error as AxiosError).response?.data as AxiosErrorDatas;

      setErrorMessage(errData.message);
      setPageStep('error');
    }
  };

  const checkUserInfo = async () => {
    try {
      const code = searchQueryParams.get('code');
      const iss = searchQueryParams.get('iss');

      setFranceConnect(iss && iss.includes('franceconnect') ? true : false);

      if (code) {
        const loginReturn =
          iss && iss.includes('franceconnect')
            ? await authService.franceConnectCheckUserInfo(code)
            : await authService.proSanteConnectCheckUserInfo(code);

        localStorage.setItem('appAuth', iss && iss.includes('franceconnect') ? 'franceconnect' : 'prosanteconnect');
        localStorage.setItem('accessToken', loginReturn.accessToken);
        localStorage.setItem('refreshToken', loginReturn.refreshToken);
        localStorage.setItem('idToken', loginReturn.idToken);

        setAppToken(loginReturn.appToken);
        setAppConnectEmail(loginReturn.appConnectEmail ? loginReturn.appConnectEmail : '');

        if (loginReturn.appConnectEmail && !loginReturn.hasEmail) {
          setCheckUserInProgress(false);
          setPageStep(`askCreateOrSelectMergeAccount`);
        } else if (loginReturn.appConnectEmail && loginReturn.askMergeAccount) {
          setCheckUserInProgress(false);
          setPageStep(`askMergeAccount`);
        } else if (loginReturn.hasEmail && !loginReturn.hasEmailConfirmed) {
          setCheckUserInProgress(false);
          setPageStep(`activeAccount`);
        } else if (loginReturn.hasEmail && loginReturn.hasEmailConfirmed) {
          redirect();
        } else {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('idToken');
          localStorage.removeItem('appAuth');

          setCheckUserInProgress(false);
          setPageStep(`emailRequest`);
          toastQueueRef.current?.addToast({
            timer: 10000,
            description: `Connexion à ${franceConnect ? 'France Connect' : 'Pro Santé Connect'} réussie !`,
            type: 'SUCCESS',
          });
        }
      } else {
        navigate(`/login`);
      }
    } catch (error) {
      setCheckUserInProgress(false);
      setPageStep('error');
    }
  };

  const redirect = async () => {
    const redirectUrl = localStorage.getItem('redirectUrl');

    if (redirectUrl) {
      window.location.href = redirectUrl.includes('?')
        ? `${redirectUrl}&accessToken=${localStorage.getItem('accessToken')}&refreshToken=${localStorage.getItem('refreshToken')}`
        : `${redirectUrl}?accessToken=${localStorage.getItem('accessToken')}&refreshToken=${localStorage.getItem('refreshToken')}`;
    } else {
      window.location.href = `${
        import.meta.env.VITE_SKEZIA_FRONT_URL
      }/login?accessToken=${localStorage.getItem('accessToken')}&refreshToken=${localStorage.getItem('refreshToken')}`;
    }
  };

  const sendEmailMergeValidation = async ({ email }: emailType) => {
    try {
      const code = searchQueryParams.get('code');

      if (code) {
        await usersService.sendEmailMergeValidation(email, appToken);

        setPageStep(`selectMergeAccountMailSend`);
      }
    } catch (error) {
      const errData = (error as AxiosError).response?.data as AxiosErrorDatas;

      setErrorMessage(errData.message);
      setPageStep('error');
    }
  };

  const createAccount = async () => {
    try {
      const code = searchQueryParams.get('code');

      if (code) {
        const createAccountReturn = await authService.fcPscCreateAccount(appToken);

        localStorage.setItem('accessToken', createAccountReturn.accessToken);
        localStorage.setItem('refreshToken', createAccountReturn.refreshToken);

        redirect();
      }
    } catch (error) {
      const errData = (error as AxiosError).response?.data as AxiosErrorDatas;

      setErrorMessage(errData.message);
      setPageStep('error');
    }
  };

  const mergeAccount = async () => {
    try {
      const code = searchQueryParams.get('code');

      if (code) {
        authService.fcPscMergeAccount(appToken);

        toastQueueRef.current?.addToast({
          timer: 10000,
          description: "Fusion de vos comptes réussie. Vous allez etre redirigé vers l'application.",
          type: 'SUCCESS',
        });
        setTimeout(function () {}, 4000);

        redirect();
      }
    } catch (error) {
      const errData = (error as AxiosError).response?.data as AxiosErrorDatas;

      setErrorMessage(errData.message);
      setPageStep('error');
    }
  };

  return (
    <>
      <ToastQueue ref={toastQueueRef} />
      {checkUserInProgress ? (
        <div className='flex h-screen items-center justify-center'>
          <Icon.Spin className='w-[6vw]' />
        </div>
      ) : (
        <div className={`z-[10] w-full rounded-lg bg-white p-6`}>
          {pageStep === 'emailRequest' && (
            <>
              <div className='py-3 text-center text-xl font-bold text-green-dark'>Finaliser votre inscription</div>
              <div className='text-center text-gray-primary'>
                Pour terminer votre inscription, veuillez fournir une adresse e-mail afin de l’associer à votre compte
              </div>
              <FormProvider {...checkUserMethods}>
                <form onSubmit={checkUserMethods.handleSubmit(sendEmailValidation)}>
                  <Controller
                    name='email'
                    control={checkUserMethods.control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <>
                        <InputWord
                          label='Email'
                          type='text'
                          placeholder='Email'
                          value={value}
                          onChangeValue={onChange}
                          errorMessage={error?.message}
                        />
                      </>
                    )}
                  />
                  <BasicButton label='Valider' fullWidth={true} isSubmitButton={true} />
                </form>
              </FormProvider>
            </>
          )}
          {pageStep === 'emailSendSuccess' && (
            <>
              <div className='py-3 text-center text-xl font-bold text-green-dark'>Félicitations !</div>
              <div className='text-center text-gray-primary'>
                Votre compte a été crée avec succès ! Un e-mail de confirmation vient de vous être envoyé pour activer votre compte.
              </div>
              <div className='mt-4 flex justify-center'>
                <BasicButton
                  label='Fermer'
                  fullWidth={false}
                  onClick={() => {
                    navigate(`/login`);
                  }}
                />
              </div>
            </>
          )}
          {pageStep === 'askCreateOrSelectMergeAccount' && (
            <>
              <div className='py-3 text-center text-xl font-bold text-green-dark'>Associer votre compte</div>
              <div className='text-center'>
                Aucun compte SKEZI n’est associé à ce compte {franceConnect ? 'FC' : 'PSC'}
                {appConnectEmail && ` (${appConnectEmail})`}.
                <br />
                <br />
                Souhaitez-vous associer votre compte {franceConnect ? 'FC' : 'PSC'} à un compte déjà existant ou créer un nouveau compte ?
              </div>
              <div className='flex justify-center'>
                <span className='mr-4'>
                  <BasicButton
                    label='Associer à un compte existant'
                    fullWidth={false}
                    template='white'
                    onClick={() => {
                      setPageStep('selectMergeAccount');
                    }}
                  />
                </span>
                <BasicButton
                  label='Créer un nouveau compte'
                  fullWidth={false}
                  onClick={() => {
                    createAccount();
                  }}
                />
              </div>
            </>
          )}
          {pageStep === 'selectMergeAccount' && (
            <>
              <div className='py-3 text-center text-xl font-bold text-green-dark'>Associer votre compte</div>
              <div className='text-center text-gray-primary'>
                Pour associer votre compte {franceConnect ? 'FC' : 'PSC'} à votre compte SKEZI existant, veuillez entrer les informations de
                connexion de votre compte.
              </div>
              <FormProvider {...checkUserMethods}>
                <form onSubmit={checkUserMethods.handleSubmit(sendEmailMergeValidation)}>
                  <Controller
                    name='email'
                    control={checkUserMethods.control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <>
                        <InputWord
                          label='Email'
                          type='text'
                          placeholder='Email'
                          value={value}
                          onChangeValue={onChange}
                          errorMessage={error?.message}
                        />
                      </>
                    )}
                  />
                  <BasicButton label='Associer mes comptes' fullWidth={true} isSubmitButton={true} />
                </form>
              </FormProvider>
            </>
          )}
          {pageStep === 'selectMergeAccountMailSend' && (
            <>
              <div className='py-3 text-center text-xl font-bold text-green-dark'>Un message a été envoyé sur votre adresse mail</div>
              <div className='text-center'>
                Veuillez vérifier votre boîte de réception pour trouver un e-mail contenant un lien de vérification, afin d&apos;activer
                votre compte.
              </div>
              <div
                onClick={() => setPageStep('connexion')}
                className='mt-3 cursor-pointer text-center font-bold text-green-secondary hover:underline'
              >
                Retour à la page de connexion
              </div>
            </>
          )}
          {pageStep === 'askMergeAccount' && (
            <>
              <div className='py-3 text-center text-xl font-bold text-green-dark'>Fusionner vos comptes</div>
              <div className='text-center'>
                Nous avons remarqué que l&apos;adresse mail renseignée lors de votre connexion à $
                {franceConnect ? 'France Connect' : 'Pro Santé Connect'}
                {appConnectEmail && ` (${appConnectEmail})`} est déjà associée à un compte sur notre solution.
                <br />
                <br />
                Pour simplifier l&apos;accès à vos informations, vous pouvez fusionner vos comptes.
              </div>
              <div className='flex justify-center'>
                <span className='mr-4'>
                  <BasicButton
                    label='Se connecter sans fusionner'
                    fullWidth={false}
                    template='white'
                    onClick={() => {
                      setPageStep('emailRequest');
                    }}
                  />
                </span>
                <BasicButton
                  label='Fusionner mes comptes'
                  fullWidth={false}
                  onClick={() => {
                    mergeAccount();
                  }}
                />
              </div>
            </>
          )}
          {pageStep === 'activeAccount' && (
            <>
              <div className='py-3 text-center text-xl font-bold text-green-dark'>Votre compte n&apos;est pas encore activé</div>
              <div className='text-center'>
                Vous avez essayé de vous connecter, mais votre adresse email n&apos;as pas encore été validée. Vous allez recevoir un nouvel
                email d&apos;activation, veuillez suivre les instructions indiquées dans celui-ci.
              </div>
            </>
          )}
          {pageStep === 'error' && (
            <>
              <ConnexionFormHeader
                title={errorMessage ? '' : `Un problème technique est survenu`}
                subTitle={errorMessage ? errorMessage : `Désolé, une erreur est survenue. Veuillez réessayer plus tard.`}
              />
              <BasicButton label='Se connecter' fullWidth={true} onClick={() => navigate(`/login`)} />
            </>
          )}
        </div>
      )}
      <Seo title={'Connexion FC / PSC'} />
    </>
  );
};

export default CheckUserComponent;
